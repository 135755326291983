module.exports = {
  theme: {
    colors: {
      transparent: 'transparent',
      'gray-darker': '#171717',
      'gray-dark': '#212121',
      gray: '#878785',
      accent: '#F2994A',
      'accent-dark': '#C87328',
      white: '#FFFFFF',
    },

    container: {
      center: true,
      padding: '1.5rem',
    },

    fontFamily: {
      sans: [
        'Rubik',
        'system-ui',
        '-apple-system',
        'BlinkMacSystemFont',
        '"Segoe UI"',
        'Roboto',
        '"Helvetica Neue"',
        'Arial',
        '"Noto Sans"',
        'sans-serif',
        '"Apple Color Emoji"',
        '"Segoe UI Emoji"',
        '"Segoe UI Symbol"',
        '"Noto Color Emoji"',
      ],
      script: ['Yellowtail', 'Georgia', 'cursive'],
    },

    extend: {
      borderRadius: {
        xl: '0.75rem',
        '2xl': '1rem',
      },

      fontSize: {
        '3xs': '0.65rem',
        '2xs': '0.7rem',
        '7xl': '5rem',
        '8xl': '6rem',
        '9xl': '7rem',
        '10xl': '8rem',
      },

      inset: (theme) => ({
        ...theme('spacing'),
        '1/4': '25%',
        '1/2': '50%',
        '3/4': '75%',
        full: '100%',
      }),

      screens: {
        '2xl': '1440px',
      },

      zIndex: {
        sub: '-1',
      },
    },
  },

  // Possible variants
  // By default, most either have [responsive], or [responsive, focus, hover]
  // - hover focus active disabled visited
  // - first last odd even
  // - group-hover group-focus focus-within.
  variants: {
    boxShadow: ['responsive', 'focus', 'hover', 'group-hover', 'group-focus'],
    color: ['responsive', 'focus', 'hover', 'group-hover', 'group-focus'],
    margin: ['responsive', 'focus', 'hover', 'first', 'last'],
  },

  plugins: [require('@tailwindcss/custom-forms')],
  purge: ['./src/**/*.js', './src/**/*.module.css'],
};
