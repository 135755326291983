import React from 'react';
import PropTypes from 'prop-types';

/**
 * Global state module to hold any application state that needs to
 * be persisted across multiple pages
 */
export const GlobalContext = React.createContext();

const reducer = (state, action) => {
  switch (action.type) {
    case 'menu': {
      return { ...state, menuOpen: action.payload };
    }

    case 'lock-scroll': {
      return { ...state, scrollLocks: state.scrollLocks + 1 };
    }

    case 'unlock-scroll': {
      return state.scrollLocks === 0 ? state : { ...state, scrollLocks: state.scrollLocks - 1 };
    }

    default: {
      throw new Error(`No handler for action: ${action.type}`);
    }
  }
};

export const GlobalProvider = ({ children }) => {
  const [state, dispatch] = React.useReducer(reducer, {
    menuOpen: false,
    scrollLocks: 0,
  });

  const lockScroll = state.scrollLocks > 0;
  React.useEffect(() => {
    const fn = lockScroll ? 'add' : 'remove';
    document.body.classList[fn]('overflow-hidden');
  }, [lockScroll]);

  return (
    <GlobalContext.Provider
      value={{
        ...state,

        openMenu: React.useCallback(() => dispatch({ type: 'menu', payload: true }), []),
        closeMenu: React.useCallback(() => dispatch({ type: 'menu', payload: false }), []),
        toggleMenu: React.useCallback(() => dispatch({ type: 'menu', payload: !state.menuOpen }), [
          state.menuOpen,
        ]),

        lockScroll: React.useCallback(() => dispatch({ type: 'lock-scroll' }), []),
        unlockScroll: React.useCallback(() => dispatch({ type: 'unlock-scroll' }), []),
      }}
    >
      {children}
    </GlobalContext.Provider>
  );
};

GlobalProvider.propTypes = {
  children: PropTypes.node.isRequired,
};

export const GlobalConsumer = GlobalContext.Consumer;

export const useGlobal = () => React.useContext(GlobalContext);
